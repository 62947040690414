<template>
  <section class="obras-vigentes-ver-equipos container-fluid overflow-auto custom-scroll pb-3">
       <!-- equipos cotizados -->
        <titulo-divisor titulo="Equipos cotizados">
            <div class="row">
                <div class="col-auto my-auto px-1">
                    <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                        <template #content>
                            <p class="p-2 item"
                                :class="permitAction('obras.agregar.equipo.modelo') ? 'cr-pointer' : 'cr-not-allowed'" 
                                @click="functionPermitAction('obras.agregar.equipo.modelo', agregarEquipoModelo, [producto.id_oportunidad])"
                            >Añadir equipo modelo</p>
                            <p class="p-2 item"
                                :class="permitAction('obras.agregar.equipo.modelo') ? 'cr-pointer' : 'cr-not-allowed'" 
                                @click="functionPermitAction('obras.agregar.equipo.modelo', agregarOportunidadEquipo)"
                            >Cargar de otra oportunidad</p>
                        </template>
                        <button class="btn btn-general f-12">
                            <p class="d-middle">
                                Añadir equipo
                                <i class="icon-menu-down ml-2 f-14" />
                            </p>
                        </button>
                    </el-tooltip>
                </div>
            </div>
        </titulo-divisor>
        <!-- listado equipos -->
        <div class="row">
            <div class="col-auto" v-for="(equipo, index) in equipos" :key="index" @click="selectEquipo(index, equipo)">
                <img :src="equipo.foto | helper-storage" alt="" class="wh-110 br-4 cr-pointer" 
                    :class="index == select ? 'border-general2' : 'border-dd2 op-05'">
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-auto">
                <img src="/img/pruebas/equipo1.png" alt="" class="wh-110 border-general2 br-4 cr-pointer">
            </div>
            <div class="col-auto">
                <img src="/img/pruebas/equipo1.png" alt="" class="wh-110 border-dd2 br-4 cr-pointer ">
            </div>
        </div> -->
        <!-- info equipo -->
        <div v-if="Object.entries(producto).length" class="row mt-4">
            <div class="col-12">
                <div class="shadow29 border br-4 px-3 py-4 info-equipo">
                    <el-tooltip content="Eliminar equipo" placement="bottom-end" effect="light" visible-arrow>
                        <div class="icon-option eliminar-equipo" @click="functionPermitAction('obras.eliminar.equipo.modelo', eliminarEquipo)">
                            <i class="icon-trash-can-outline f-23 cr-pointer" :class="permitAction('obras.eliminar.equipo.modelo') ? 'cr-pointer' : 'cr-not-allowed'" />
                        </div>
                    </el-tooltip>
                    <div class="row">
                        <div class="col-auto">
                            <img :src="producto.foto | helper-storage" alt="" class="wh-151 border-dd br-4">
                        </div>
                        <div class="col">
                            <p class="f-18 f-700">{{producto.nombre}}</p>
                            <div class="row my-3">
                                <div class="col-6 col-lg-6 col-xl">
                                    <p class="f-500 f-12">
                                        Precio:
                                        <span class="f-300">{{formatoMoneda(producto.precio)}}</span>
                                    </p>
                                </div>
                                <div class="col-6 col-lg-6 col-xl">
                                    <p class="f-500 f-12">
                                        Etapas:
                                        <span class="f-300">{{producto.numero_etapas}}</span>
                                    </p>
                                </div>
                                <div class="col-6 col-lg-6 col-xl">
                                    <p class="f-500 f-12">
                                        Materiales:
                                        <span class="f-300">{{producto.materiales}}</span>
                                    </p>
                                </div>
                                <div class="col-6 col-lg-6 col-xl">
                                    <p class="f-500 f-12">
                                        Horas hombre:
                                        <span class="f-300"> {{producto.horas_hombre}}</span>
                                    </p>
                                </div>
                            </div>
                            <p class="f-12 lh-15">{{producto.descripcion}}</p>
                        </div>
                    </div>
                    <titulo-divisor titulo="Etapas de equipo" class="mr-0">
                        <div class="row">
                            <div class="col-auto my-auto px-1 icon-option">
                                <el-tooltip content="Añadir etapa" placement="bottom" effect="light" visible-arrow popper-class="like-buttons">
                                    <i 
                                    class="icon-plus-circle f-30"
                                    :class="permitAction('obras.agregar.etapa.equipo.modelo') ? 'cr-pointer' : 'cr-not-allowed'" 
                                    @click.stop="functionPermitAction('obras.agregar.etapa.equipo.modelo', agregarEtapaEquipo)"
                                    />
                                </el-tooltip>
                            </div>
                        </div>
                    </titulo-divisor>
                    <!-- listado etapas -->
                    <div class="row mx-0">
                        <div class="col-12 obras-vigentes-equipos-collapse" v-for="(etapa, index) in producto.etapas" :key="index">
                            <el-collapse v-model="activeNames" @change="handleChange">
                                <el-collapse-item name="1">
                                    <template slot="title">
                                        <div class="d-flex f-600 w-100 justify-content-between">
                                            <p class="text-title">{{etapa.etapa}}</p>
                                            <div class="cant-item d-middle-center br-4 my-auto ml-auto f-300 icon-option">
                                                <el-tooltip content="Editar" effect="light" placement="bottom" visible-arrow>
                                                  <i 
                                                    class="icon-pencil f-20"
                                                    :class="permitAction('obras.editar.etapa.equipo.modelo') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                    @click.stop="functionPermitAction('obras.editar.etapa.equipo.modelo', editarEtapa, [etapa])"
                                                  />
                                                </el-tooltip>
                                                <el-tooltip content="Eliminar" effect="light" placement="bottom" visible-arrow>
                                                  <i 
                                                    class="icon-trash-can-outline f-20"
                                                    :class="permitAction('obras.eliminar.etapa.equipo.modelo') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                    @click.stop="functionPermitAction('obras.eliminar.etapa.equipo.modelo', eliminarEtapa, [etapa])"
                                                  
                                                  />
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </template>
                                    <div v-for="(configuracion, i) in etapa.configuraciones" :key="i" class="row mx-0 text-5d my-2">
                                        <div class="col-5 my-auto">
                                          <p class="f-14 f-500 cr-pointer" @click="view_configuracion(configuracion)">{{configuracion.nombre}}</p>
                                        </div>
                                        <div class="col-3 my-auto">
                                          <div class="d-middle">
                                            <i class="icon-cash-multiple f-20" />
                                            <p class="f-14">$ {{formatoMoneda(configuracion.valor)}}</p>
                                          </div>
                                        </div>
                                        <div class="col-3 my-auto">
                                          <div class="d-middle">
                                            <i class="icon-account-clock f-20" />
                                            <p class="f-14">{{configuracion.horas_hombre}}</p>
                                          </div>
                                        </div>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <p class="d-middle-center f-20 text-muted mt-5 f-600">No se encontraron equipos</p>
        </div>
        <!-- partials -->
        <modal-agregar-equipo-modelo ref="modalAgregarEquipoModelo" />
        <modal-agregar-equipo-op ref="modalAgregarEquipoOp" />
        <modal-eliminar ref="abrirModalEliminarEquipo" title="Eliminar equipo" mensaje="¿Está seguro que quiere eliminar este equipo?" @delete="deleteEquipo"/>
        <modal-agregar-etapa-equipo ref="modalAgregarEtapaEquipo" />
        <modal-editar-etapa ref="modalEditarEtapa" @reloadStates="reloadStates" />
        <modal-eliminar ref="abrirEliminarEtapa" title="Eliminar etapa" mensaje="¿Está seguro que quiere eliminar esta etapa?" @delete="eliminarEtapaEquipo"/>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations} from 'vuex'
export default {
  components:{
    modalAgregarEquipoModelo: () => import('../partials/modalAgregarEquipoModelo'),
    modalAgregarEquipoOp: () => import('../partials/modalAgregarEquipoOp'),
    modalAgregarEtapaEquipo: () => import('../partials/modalAgregarEtapaEquipo'),
    modalEditarEtapa: () => import('../partials/modalEditarEtapa'),
  },
  data(){
      return{
          activeNames: [],
          checkList: [],
          select: 0,
          idEliminarEtapa:'',
          idEquipo:''
      }
  },
    async created(){
        await this.listarEquipos()
    },
    computed:{
        ...mapGetters({
            equipos: 'obras/informacion/equipos',
            producto: 'obras/informacion/producto',
            select_projects: 'selects/selects/select_projects',
        })
    },
  methods:{
        ...mapActions({
            Action_get_informacion_equipos: 'obras/informacion/Action_get_informacion_equipos',
            Action_eliminar_etapa: 'obras/informacion/Action_eliminar_etapa',
            Action_eliminar_equipo: 'obras/informacion/Action_eliminar_equipo',
            Action_get_select_projects: 'selects/selects/Action_get_select_projects',

        }),
        ...mapMutations({
            setProducto : 'obras/informacion/setProducto'
        }),
        async listarEquipos(){
            await this.Action_get_informacion_equipos(this.$route.params.id)
            console.log({equipos:this.equipos});
            if(this.equipos.length) {
                this.selectEquipo(0, this.equipos[0])
            }else{
                this.setProducto({})
            }
            await this.Action_get_select_projects()
        },
        selectEquipo(index, equipo){
            this.select = index
            this.setProducto(equipo)
        },
        reloadStates(){
            this.setProducto(this.equipos[this.select])
        },
        handleChange(){

        },
        agregarEquipoModelo(id){
            this.$refs.modalAgregarEquipoModelo.toggle(id)
        },
        agregarOportunidadEquipo(){
            this.$refs.modalAgregarEquipoOp.toggle()
        },
        eliminarEquipo(){
            this.$refs.abrirModalEliminarEquipo.toggle()
        },
        agregarEtapaEquipo(){
            this.$refs.modalAgregarEtapaEquipo.toggle()
        },
        editarEtapa(etapa){
            this.$refs.modalEditarEtapa.toggle(etapa)
        },
        eliminarEtapa(etapa){
            this.idEliminarEtapa = etapa.id
            this.$refs.abrirEliminarEtapa.toggle()
        },
        async eliminarEtapaEquipo(){
            let payload = {
                id_proyecto: this.$route.params.id,
                id_producto: this.producto.id_producto,
                id_oportunidades_producto_etapas: this.idEliminarEtapa,
                tabla: this.producto.tabla,
            }
            await this.Action_eliminar_etapa(payload)
            await this.Action_get_informacion_equipos(this.$route.params.id)
            this.setProducto( this.equipos.find( f => f.id == this.producto.id ))

        },
        async deleteEquipo(){
            let payload = {
                id_proyecto: this.$route.params.id,
                id_proyecto_producto: this.producto.id,
                tabla: this.producto.tabla,
            }
            await this.Action_eliminar_equipo(payload)
            await this.listarEquipos()
        },
        view_configuracion(configuracion){
            this.$router.push({ name: 'obras.vigentes.ver.configuracion.materiales', params: {
                 id: this.$route.params.id,
                 id_configuracion: configuracion.id,
                 tabla: this.producto.tabla
                }
            })
        }
  }

}
</script>

<style lang="scss" scoped>
.obras-vigentes-ver-equipos{
  height: calc(100vh - 415px);
  .info-equipo{
      position: relative;
      .eliminar-equipo{
          position: absolute;
          top: 6px;
          right: 7px;
          z-index: 1;
      }
  }
}
</style>